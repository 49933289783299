<template>

<app-wrapper class="services">

	<app-wrapper :content="true" class="services-content">

		<router-link v-for="(item, index) in $state.links" :key="index" :to="{name: 'Service', params: { service: item.url }}" class="services-content-item">

			<h3>{{ item.name }}</h3>

			<p>{{ item.description }}</p>
			
		</router-link>
		
	</app-wrapper>

</app-wrapper>

</template>

<script>

export default {

}

</script>

<style scoped>

.services-content {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-auto-rows: 1fr;
	margin-top: -250px!important;
	grid-gap: 20px;
	padding-bottom: 100px;
}

.is-tablet .services-content {
	grid-template-columns: 1fr;
	margin-top: -150px!important;
	grid-gap: 64px;
}

.is-mobile .services-content {
	grid-template-columns: 1fr;
	margin-top: -100px!important;
	grid-gap: 40px;
	padding-bottom: 40px!important;
}

.services-content-item {
	background-color: #fff;
	border-radius: 5px;
	border: 1px solid #BCD9CD;
	border-bottom-width: 10px;
	padding: 30px 20px;
}

.is-mobile .services-content-item {
	padding: 20px 10px;
}

.services-content-item h3 {
	color: #112A45;
	font-size: 24px;
	letter-spacing: 1.2px;
	margin-bottom: 30px;
	text-align: center;
}

.is-mobile .services-content-item h3 {
	font-size: 20px;
}

.services-content-item p {
	color: #585A5D;
	font-size: 16px;
	line-height: 32px;
	margin-bottom: 20px;
	text-align: center;
}

.is-mobile .services-content-item p {
	font-size: 14px;
	line-height: 24px;
}

.services-content-item p:last-child {
	margin-bottom: 0px;
}

</style>
